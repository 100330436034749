.subject-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 18px 22px;
    box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
    min-height: 73px;
    justify-content: center;
}

.subject-item-selected {
    border: 1px solid var(--accent-color);
}

.subject-item-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.subject-item-header-right {
    display: flex;
    flex-direction: column;
    position: relative;
}

.subject-item-header-right-quota {
    display: flex;
    align-items: center;
    gap: 14px;
    justify-content: space-between;
}

.subject-item-header-right-quotaType {
    font-size: 8px;
    display: flex;
    flex-direction: column;
    width: 15ch;
}

.subject-item-header-right-icon {
    color: var(--accent-color);
    font-size: 20px;
    cursor: pointer;
}

.subject-item-header-right-icon::part(svg) {
    stroke: var(--accent-color)
}

.subject-item-header-checkbox::part(base) {
    display: flex;
    align-items: center;
    gap: 12px;
}

.subject-item-header-checkbox::part(control) {
    border: 1px solid var(--accent-color);
    border-radius: 12px;
}

.subject-item-header-checkbox::part(control--checked) {
    background-color: transparent;
}

.subject-item-header-checkbox::part(checked-icon) {
    position: relative;
}

.subject-item-header-checkbox::part(checked-icon)::after {
    content: '';
    position: absolute;
    background-color: var(--accent-color);
    border-radius: 99999px;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.subject-item-header-checkbox-label-text {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.subject-item-header-checkbox-label-text-title {
    font-size: 14px;
    font-weight: bold;
}

.subject-item-header-checkbox-label-text-tagline {
    font-size: 12px;
}

.subject-item-data-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows var(--expand-animation-length) var(--expand-animation-timing);
}

.subject-item-data-wrapper-expanded {
    grid-template-rows: 1fr;
    padding: 17px 0;
    transition: all var(--expand-animation-length) var(--expand-animation-timing);
}

.subject-item-data-animation {
    min-height: 0;
}

.subject-item-data-transform-wrapper {
    transform: translateY(-100%);
    visibility: hidden;
    transition: transform var(--expand-animation-length) var(--expand-animation-timing),
    visibility var(--expand-animation-length) var(--expand-animation-timing);
}

.subject-item-data-transform-wrapper-expanded {
    transform: translateY(0);
    visibility: visible;
    transition: transform var(--expand-animation-length) var(--expand-animation-timing),
    visibility 0ms linear;
}

.subject-item-data-description {
    margin-top: 11px;
}

.subject-no-available{
    color: white;
    padding: 2px 5px;
    font-size: 8px;
    background-color: var(--text-muted);
    border-radius: 4px;
}