.mode-header {
    background-color: #FF6262;
    color: white;
    padding: 4px 0;
    text-align: center;
    width: 100%;
    height: 17px;
    position: absolute;
    z-index: 99;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 11px;
}

.bypass{
    background-color:#426DDB;
}
.maintenance{
    background-color:#EBD516;
}
