.subject-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 15px 0;
}

.subject-detail-type {
    font-size: 12px;
    font-weight: bold;
    color: var(--text-accent);
    background-color: var(--accent-light-color);
    padding: 4px 8px;
    border-radius: 31px;
}

.subject-detail-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    width: 100%;
    padding: 10px 0;
}

.subject-detail-data-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    padding-left: 8px;
    flex: 1;
}

.vertical-separator {
    border-right: 1px solid var(--sl-panel-border-color);
}

.subject-detail-data-info-label {
    font-size: 12px;
    color: var(--text-muted);
}

.subject-detail-data-info-value {
    font-size: 12px;
}
.subjects-value-box{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.text-bold{
    font-weight: 600;
}