.desktop-subject-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding:14px 15px;
    box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
    min-height: 73px;
    justify-content: center;
    gap: 12px;
}
.desktop-item-first-row{
    display: flex;
    align-items: center;
    gap: 15px;
}

.desktop-item-second-row{
    display: flex;
    gap: 20px;
}

.desktop-inner-col{
    font-size: 12px;
}
.desktop-inner-col:first-child{
    width: 200px;
    max-width: 230px;
}
.desktop-inner-col:last-child{
    flex-grow: 1;
    text-align: right;
}
.desktop-subject-type{
    color: var(--text-muted);
}
.desktop-icon-user-quota{
    font-size: 16px;
}

.desktop-item-title{
    font-size: 16px;
    font-weight: bold;
    flex-grow: 1;
    cursor: pointer;
}

.desktop-items-actions{
    display: flex;
    gap: 8px;
}

.desktop-action{
    border-radius: 100vh;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--accent-color);
    font-size: 15px;
    border: 2px solid var(--accent-color);
    background-color: white;
    cursor: pointer;
}
.desktop-action:hover{
    background-color: var(--accent-color-150);
}
.desktop-action:active{
    background-color: white;
}
.desktop-action-add:hover{
    background-color: var(--accent-color-darken);
}
.desktop-action-add:active{
    background-color: var(--accent-color);
}
.desktop-action-add{
    background-color: var(--accent-color);
    color: white;
    border: none;
    width: 125px;
    font-size: 16px;
    font-weight: bold;
    height: 32px;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
}

.desktop-pinned-subject{
    border: 2px solid var(--accent-color);
    background-color: var(--accent-color-bg);
}

.desktop-pinned-subject .btn-pin{
    background-color: var(--accent-color);
    color: white;
}

.desktop-pinned-subject .btn-pin:hover{
    background-color: var(--accent-color-darken);
    border-color: var(--accent-color-darken);
}

.desktop-items-actions .btn-calendar{
    background-color: var(--accent-color);
    color: white;
}
.desktop-items-actions .btn-calendar:hover{
    background-color: var(--accent-color-darken);
    border-color: var(--accent-color-darken);
}