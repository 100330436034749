.header {
    padding: 22px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 8%);
    margin-bottom: 20px;
}

.header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-info-logo {
    display: flex;
    align-items: center;
}

.header-info-data {
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}