.desktop-main{
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    gap: 40px;
}
.desktop-left{
    width: 40%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
    height: 80vh;
    position: sticky;
    top: 0;
    border-radius: 8px;
    padding: 10px;
    color: rgb(145, 145, 145);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    
}
.right{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.desktop-inscribed-calendar-tab{
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop-left-tab{
    flex-grow: 1;
    padding: 10px;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid var(--text-muted);
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: var(--text-muted);
}
.desktop-left-tab-active{
    color: black;
    border-color: black;
}