.subjects-tab{
    display: flex;
    font-weight: bold;
    color: var(--text-muted);
}

.subjects-tab-button{
    cursor: pointer;
    border-bottom: 2px solid var(--text-muted);
    flex-grow: 1;
    text-align: center;
    padding: 6px;
}

.tab-active{
    border-color: black;
    color: black;
}