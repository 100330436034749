.navbar {
    background-color: white;
    position: sticky;
    /* height: 90px; */
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0px 0 20px 0;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}

.navbar-item {
    min-width: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    color: black;
    text-decoration: none;
    cursor: pointer;
    min-width: 70px;
    padding: 10px 6px 0 6px;
    border-top: 4px solid transparent;
}

.navbar-item-icon {
    font-size: 32px;
}

.navbar-item-label {
    font-size: 11px;
}
.editor-mode-btn{
    outline: none;
    background-color: transparent;
    border: none;
}

.editor-mode-btn-on{
    color: var(--accent-color);
}

.active-path{
    border-top: 5px solid var(--accent-color);
}