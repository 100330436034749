.shoelace-modal::part(body) {
    position: relative;
    overflow: hidden;
}

.custom-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    gap: 18px;
}

.custom-modal::after {
    content: "";
    width: 100%;
    height: 10px;
    background-color: var(--accent-color);
    position: absolute;
    top: 0;
}

.custom-modal-message {
    color: var(--text-primary);
    font-size: 14px;
    width: 30ch;
}

.custom-modal-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.custom-modal-actions-button-ok::part(base) {
    background-color: var(--accent-color);
    border: 0;
    color: white;
    font-weight: bold;
}

.custom-modal-actions-button-cancel::part(base) {
    background-color: transparent;
    border: 0;
    color: var(--accent-color);
    font-weight: bold;
}