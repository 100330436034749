.subject-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 7px;
}

.subject-description-title {
    color: var(--text-accent);
    font-size: 12px;
    font-weight: bold;
}

.subject-description-info {
    font-size: 12px;
}

.subject-description-bottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.subject-description-bottom-more {
    color: var(--text-accent);
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}