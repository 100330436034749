.calendar-image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    height: 100%;
}
.calendar-image, .empty-calendar-msg{
    width: 80%;
    max-width: 300px;
    text-align: center;
}