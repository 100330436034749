.page{
    height: 100vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.row{
    width: 100%;
    max-width: 270px;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.input-test{
    flex-grow: 1;
}
.full-width{
    width: 100%;
}

.text-center{
    text-align: center;
}