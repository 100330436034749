.desktop-subjects-tab{
    background: #EEEEEE;
    color: #A5A3A3;
    border-radius: 6px;
    display: flex;
    width: 250px;
    min-width: 250px;
}

.desktop-subjects-tab-button{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    text-align: center;
    padding: 6px;
    transition: translate ease 0.3s;
}

.desktop-tab-active{
    border-radius: 6px;
    background-color: #656464;
    color: white;
}