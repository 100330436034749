.custom-detail-modal{
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    }

.inner-modal-details{
    width: 80%;
    max-width: 400px;
    background-color: white;
    border: 1px var(--border-color) solid;
    border-top: 7px solid var(--accent-color);
    padding: 16px 12px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 8%);
}
.detail-modal-title{
    margin-bottom: 6px;
}

.detail-modal-section{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.detail-modal-day{
    background-color: var(--accent-color);
    padding: 1px 6px;
    margin-right: 8px;
    color: white;
    border-radius: 100px;
}
.detail-modal-label{
    color: var(--text-disabled);
}
.detail-modal-divider{
    padding-bottom: 12px;
}