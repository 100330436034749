.selected-subjects {
    position: relative;
}

.selected-subjects-button {
    display: flex;
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 18px;
    box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
    justify-content: space-between;
}

.selected-subjects-button-text {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
}

.selected-subjects-button-quantity {
    background-color: var(--accent-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99999px;
    width: 20px;
    height: 20px;
    padding-right: 1px;
    color: white;
}

.selected-subjects-button-icon {
    color: var(--accent-color);
    font-size: 20px;
    cursor: pointer;
}

.selected-subjects-button-icon::part(svg) {
    stroke: var(--accent-color)
}

.selected-subjects-items {
    position: absolute;
    box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
    background-color: white;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    width: 100%;
    left: 0;
    top: 60px;
    z-index: 99;
}

.selected-subjects-item {
    display: flex;
    justify-content: space-between;
    padding: 14px 22px 14px 26px;
}

.selected-subjects-item-divider {
    position: relative;
}

.selected-subjects-item-divider::after {
    content: "";
    width: 94%;
    height: 1px;
    background-color: var(--border-color);
    position: absolute;
    z-index: 90;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.selected-subjects-item-left {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.selected-subjects-item-left-title {
    font-size: 14px;
    font-weight: bold;
}

.selected-subjects-item-left-tagline {
    font-size: 12px;
}

.selected-subjects-item-right {
    display: flex;
    align-items: center;
    gap: 12px;
}

.selected-subjects-item-right-icon-edit {
    color: var(--accent-color);
    font-size: 18px;
    cursor: pointer;
}

.selected-subjects-item-right-icon-edit::part(svg) {
    stroke: var(--accent-color)
}

.selected-subjects-item-right-icon-delete {
    font-size: 18px;
    cursor: pointer;
}

.hide {
    display: none;
  }