.layout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.layout-content {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 60px;
}

.layout-header {
    height: fit-content;
}

.layout-navbar {
    /* height: 11%; */
    min-height: 90px;
}