.custom-calendar {
    position: relative;
    overflow-x: hidden;
    height: 100%;
    padding-top: 10px;
}

.custom-calendar-item {
    font-weight: bold;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    text-align: center;
}

.custom-calendar-item-day {
    font-weight: bold;
    border-radius: 6px;
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.custom-calendar-item-text {
    word-break: break-all;
}

.custom-calendar-item-day-text {
    font-size: 12px;
    text-align: left;
    z-index: 200;
}

.custom-calendar-item-day-time {
    font-size: 12px;
}

.custom-calendar-separator-hour {
    position: absolute;
}

.custom-calendar-separator-hour-label {
    color: var(--text-disabled);
    font-size: 14px;
    position: absolute;
    top: 1px;
    z-index: 90;
    white-space: nowrap;
    background-color: white;
}

.custom-calendar-separator-hour::after {
    content: "";
    position: absolute;
    background-color: var(--text-disabled);
    width: 100vw;
    height: 1px;
    left: 57px;
    top: 10px;
    z-index: -1;
}

.custom-calendar-separator-half-hour {
    position: absolute;
}

.custom-calendar-separator-half-hour::after {
    content: "";
    position: absolute;
    background-color: var(--border-color);
    width: 100vw;
    height: 1px;
    left: 0;
    top: 10px;
    z-index: -2;
}


.calendar-days {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    text-align: center;
}

.calendar-days-item {
    grid-column: span 1;
    font-weight: bold;
    padding: 10px;
    font-size: 14px;
}

.calendar-days-item-active {
    grid-column: span 1;
    background-color: var(--accent-color);
    color: white;
    font-weight: bold;
    font-size: 14px;
    border-radius: 12.5px;
    text-align: center;
    align-self: center;
    padding: 4px 10px;
    cursor: pointer;
}

.calendar-days-item:first-child {
    grid-column: 2/3;
}

.calendar-container {
    border: 1px var(--light-gray) solid;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 8%);
    height: 100%;
}

.calendar-container-button {
    all: unset;
    font-weight: bold;
    font-size: 14px;
    border-radius: 12.5px;
    text-align: center;
    align-self: center;
    padding: 4px 12px;
    cursor: pointer;
}

.calendar-container-button-active {
    all: unset;
    background-color: var(--accent-color);
    color: white;
    font-weight: bold;
    font-size: 14px;
    border-radius: 12.5px;
    text-align: center;
    align-self: center;
    padding: 4px 12px;
    cursor: pointer;
}

.calendar-item-detail {
    position: absolute;
    background-color: white;
    border: 1px var(--border-color) solid;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 300;
    padding: 12px 14px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
}

.calendar-item-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.calendar-item-detail-header-close::part(svg) {
    color: var(--accent-color);
}
.calendar-item-detail-header-title {
    font-size: 14px;
    font-weight: bold;
}

.calendar-item-detail-section {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.calendar-item-detail-section-time {
    white-space: nowrap;
}

.calendar-item-detail-section-profesor {
    white-space: nowrap;
}

.calendar-item-detail-section-profesor span {
    color: var(--text-disabled)
}

.calendar-item-detail-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    font-size: 14px;
}

.calendar-item-detail-actions-edit {
    all: unset;
    border-radius: 5px;
    background-color: var(--accent-color);
    width: 90%;
    text-align: center;
    padding: 6px 0px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.calendar-item-detail-actions-delete {
    all: unset;
    color: var(--accent-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
}