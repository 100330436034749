.warning-component {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 22px;
}

.warning-component-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.warning-component-text h3 {
    color: var(--text-primary)
}

.warning-component-text p {
    font-size: 16px;
    max-width: 60ch;
    text-align: center;
    color: var(--text-muted)
}