.subjects-main-container{
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 12px;
    padding-top: 16px;
}

.subject-type-count{
    color: var(--text-muted);
    font-size: 11px;
    border-radius: 5px;
    border: 1px solid var(--text-muted);
    padding: 3px;
    font-weight: normal;
}