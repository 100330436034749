.header-info-data-user {
    display: flex;
    gap: 8px;
    align-items: center;
}

.header-info-data-user-name {
    font-size: 12px;
    color: var(--text-muted);
}

.btn-logout{
    cursor: pointer;
}

.menu-item{
    font-size: 12px;
}