.search-input::part(base) {
    background-color: var(--light-gray);
    border: 0;
}
.search-container{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 40; 
    background-color: white;
    display: flex;
}

.search-input{
    width: 100%;
    outline: none;
}

.search-input::part(prefix) {
    padding-left: 18px;
}

.clear-search{
width: 30px;
background-color: transparent;
border: none;
outline: none;
cursor: pointer;
}