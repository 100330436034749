.desktop-filters-nav{
    display: flex;
    gap: 20px;
}

.desktop-title-2{
    font-size: 20px;
    font-weight: bold;
    color: var(--text-muted);
}

.desktop-filters-listing{
    display: flex;
    flex-direction: column;
    gap: 12px;
}