.editor-actions {
    background-color: var(--accent-color);
    color: white;
    padding: 4px 0;
    text-align: center;
    width: 100%;
    position: absolute;
    z-index: 99;
    bottom: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 11px;
    padding: 5px 0;
}

@keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }

  .pulse {
    display: inline-block;
    animation: pulse 2s infinite;
  }
