.desktop-inscribed-subjects {
    padding: 10px 10px;
}
.desktop-inscribed-subjects-list{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.desktop-inscribed-subjects-count {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 0;
}

.selected-subjects-button-text {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
}

.desktop-inscribed-quantity {
    background-color: var(--accent-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99999px;
    width: 20px;
    height: 20px;
    padding-right: 1px;
    color: white;
}

.desktop-inscribed-subject-item{
    background-color: white;
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 10px 0;
    color: #424242;
    font-size: 12px;
}


.desktop-selected-subjects-item-left{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;

}
.desktop-selected-subject-item-left-title{
    font-size: 16px;
}
.desktop-btn-unsuscribe-subject:hover{
    background-color: #bd8202;
}
.desktop-btn-unsuscribe-subject:active{
    background-color: #F5A800;
}
.desktop-btn-unsuscribe-subject{
    background-color: #F5A800;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: bold;
    height: 32px;
    padding: 0 15px;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
}

.desktop-item-wrapper{
    width: 100%;
}